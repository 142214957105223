<template>
  <TippyComponent
    theme="custom"
    :interactive="interactive"
  >
    <template v-slot:trigger>
      <div
        class="flex cursor-pointer"
        @click="$emit('click')"
      >
        <slot name="content" />
      </div>
    </template>
    <slot name="tooltip" />
  </TippyComponent>
</template>

<script>
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy)

export default {
  components: {
    TippyComponent
  },

  props: {
    interactive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
